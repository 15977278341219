<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <!--    <div class="row">-->
    <!--      <div class="col-sm-12"><h1 class="text-center">This page is building</h1></div>-->
    <!--    </div>-->

    <div class="row">
      <div class="col-md-6 col-lg-4">
        <label class="form-label">Categoies</label>
        <select class="form-select form-select-sm myselect2" multiple id="categories">
          <option v-for="(item, index) in $data.categoryList" :value="item.no" :key="index">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="col-md-6 col-lg-4">
        <label class="form-label">Countries</label>
        <select class="form-select form-select-sm myselect2" multiple id="countries">
          <option v-for="(item, index) in $data.countryList" :value="item.no" :key="index">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="col-md-6 col-lg-2">
        <label class="form-label">Item pers page</label>
        <select
          class="form-select"
          id="itemPerPage"
          @change="onItemPerPageChange"
          ref="itemPerPage"
        >
          <option value="12">12</option>
          <option value="24" selected>24</option>
          <option value="36">36</option>
          <option value="48">48</option>
        </select>
      </div>

      <div class="col-md-6 col-lg-2">
        <br />
        <button class="btn btn-primary mt-2" @click="onSearchClick">Search</button>
      </div>
    </div>

    <div class="row mt-3">
      <div
        class="col-md-6 col-lg-4 text-justify mb-2"
        v-for="(item, index) in $data.brandList"
        :key="index"
      >
        <div class="float-none float-md-start text-center">
          <a :href="item.url">
            <!--            src="https://via.placeholder.com/160x70"-->
            <img
              class="img-fluid me-2 img-thumbnail"
              :src="'/uploads/brands/medium/' + item.logo"
              :alt="item.name"
            />
          </a>
        </div>
        <div>
          <a :href="item.url">
            <span class="text-primary">{{ item.name }}</span>
          </a>
          <br />
          <small class="text-muted">
            <i class="fas fa-th-list text-secondary"></i>{{ item.categories }}
          </small>
          <br />
          <small class="text-muted">
            <i class="fas fa-globe-americas text-secondary"></i>{{ item.countries }}
          </small>
        </div>
      </div>
    </div>
    <div class="row mt-3" v-if="$data.totalPages > 1">
      <div>
        <!--          :total="totalPages"-->
        <!--          :per-page="$data.itemPerPage"-->
        <pagination
          :total-pages="totalPages"
          :current-page="currentPage"
          @pagechanged="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
// from '../../common/Footer.vue';
import Pagination from '../../common/Pagination';
import jQuery from 'jquery';
window.select2 = require('select2');
const $ = jQuery.noConflict();

// Return an array of the selected opion values
// select is an HTML select element
function getSelectValues(select) {
  var result = [];
  var options = select && select.options;
  var opt;

  for (var i = 0, iLen = options.length; i < iLen; i++) {
    opt = options[i];

    if (opt.selected) {
      result.push(opt.value || opt.text);
    }
  }
  return result;
}
async function displayPage(self, page) {
  console.log('page ', page);
  let categoriesSelect = document.getElementById('categories');
  let countriesSelect = document.getElementById('countries');
  let tmpCategoryArrray = getSelectValues(categoriesSelect);
  let tmpCountryArrray = getSelectValues(countriesSelect);
  console.log(tmpCategoryArrray.join());
  console.log(tmpCountryArrray.join());
  let itemPerPage = document.getElementById('itemPerPage').value;
  let apiUrl = process.env.VUE_APP_API_URL;

  let data = {
    page: page,
    itemPerPage: itemPerPage,
    categories: tmpCategoryArrray.join(),
    countries: tmpCountryArrray.join(),
  };

  const formData = Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
  await fetch(apiUrl + 'merchant/brands', {
    method: 'POST',
    headers: {
      // 'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
    },
    body: formData,
  })
    .then((response) => response.json())
    .then((resData) => {
      console.log('Success:', resData);
      // let messages = resData.messages
      if (resData.status == 'error') {
        alert('error while geting data ' + resData.message);
        return;
      }
      self.$data.brandList = resData.brandList;
      self.$data.totalPages = resData.totalPages;
    })
    .catch(function (err) {
      console.log('error', err);
    });
}
export default {
  name: 'Merchants',
  components: {
    // ...
    pagination: Pagination,
  },
  data() {
    return {
      // ...
      currentPage: 1,
      totalPages: 0,
      categoryList: [],
      countryList: [],
      brandList: [],
      itemPerPage: 1,
    };
  },
  methods: {
    // ...
    onPageChange(page) {
      // console.log(page);
      this.currentPage = page;
      displayPage(this, page);
    },
    onItemPerPageChange: function (e) {
      this.$data.itemPerPage = e.target.value;
    },
    onSearchClick: function () {
      displayPage(this, 1);
    },
  },
  created() {
    document.title = 'Trepr - The best online deals';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        "Looking for best online deals.Trepr's overseas merchants offer products at competitive prices."
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr,Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
  },
  async mounted() {
    $('.myselect2').select2({
      theme: 'classic',
      placeholder: 'Click to choose items',
    });
    let self = this;
    let apiUrl = process.env.VUE_APP_API_URL;

    let promiseCountryList = fetch(apiUrl + 'common/custom-country-list', {
      method: 'GET',
    }).then((response) => response.json());

    let promiseCategoryList = fetch(apiUrl + 'merchant/categories-list', {
      method: 'GET',
    }).then((response) => response.json());

    let itemPerPage = document.getElementById('itemPerPage').value;
    // let itemPerPage = this.$refs.itemPerPage.value;
    let data = {
      page: 1,
      itemPerPage: itemPerPage,
      categories: '',
      countries: '',
    };

    const formData = Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
    let promiseBrads = fetch(apiUrl + 'merchant/brands', {
      method: 'POST',
      headers: {
        // 'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
      },
      body: formData,
    }).then((response) => response.json());
    await Promise.all([promiseCountryList, promiseCategoryList, promiseBrads])
      .then((resData) => {
        // console.log(resData);
        if (resData[0].status == 'error') {
          alert('error while geting data ' + resData[0].message);
          return;
        }
        self.$data.countryList = resData[0].countryList;
        if (resData[1].status == 'error') {
          alert('error while geting data ' + resData[1].message);
          return;
        }
        self.$data.categoryList = resData[1].categoryList;
        if (resData[2].status == 'error') {
          alert('error while geting data ' + resData[2].message);
          return;
        }
        self.$data.brandList = resData[2].brandList;
        self.$data.totalPages = resData[2].totalPages;
      })
      .catch((err) => {
        console.error('Error:', err);
      });
  },
};
</script>

<style lang="scss" scoped>

/* Bottom left text */
.bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
}
/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
